import SliceZone from '@containers/SliceZone'
import { ServiceUSP as ServiceUSPProps } from '@lib/types/Navigation'
import { FC, memo } from 'react'

interface Props {
  serviceUSP?: ServiceUSPProps
}

const ServiceUSP: FC<Props> = ({ serviceUSP }) => {
  return (
    <>
      <SliceZone slices={serviceUSP?.body} />
    </>
  )
}

export default memo(ServiceUSP)
